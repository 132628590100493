import React, { Fragment, useContext } from "react"
import classNames from "classnames"
import firebase from "firebase"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import {
  faEnvelope,
  faBirthdayCake,
  faLock,
  faPhone,
  faUserFriends,
  faIdCard
} from "@fortawesome/free-solid-svg-icons"
import AddressContainer from "./Addresses/AddressContainer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Notification from "./utils/Notification"
import { AppContext } from "../../context/AppContext"

const InfoIcon = ({ children, icon, className }) => {
  return (
    <p
      className={classNames(
        "is-flex is-align-items-center",
        className || ""
      )}
    >
      <span className="icon mr-1">
        <FontAwesomeIcon icon={icon} />
      </span>
      <span>{children}</span>
    </p>
  )
}

const ProfileInfo = ({ addresses, setAddresses, userData, location, user }) => {
  const { dispatch } = useContext(AppContext)

  const handleDeleteCallback = (addressDocument) => {
    setAddresses(addressDocument)
  }

  const handleResendVerification = async () => {
    let user = firebase.auth().currentUser
    let url = location.origin + "/sign-in"

    let actionCodeSettings = {
      url,
      handleCodeInApp: false,
    }

    user
      .sendEmailVerification(actionCodeSettings)
      .then(() => {
        dispatch({
          type: "SHOW_TOAST",
          payload: {
            message: `Email verification sent.`,
            color: "success",
          },
        })
      })
      .catch(() => {
        dispatch({
          type: "SHOW_TOAST",
          payload: {
            message: `Email verification cannot be sent. Please try again.`,
            color: "danger",
          },
        })
      })
  }

  let patientBirthday = ""
  if (userData?.birthday) {
    let { month, date, year } = userData?.birthday
    patientBirthday = `${month.value || month} ${date.value || date}, ${
      year.value || year
    }`
  }

  return (
    <Fragment>
      {user && !user.emailVerified && (
        <Notification color="danger">
          Your email is not yet verified. Please check your inbox then refresh
          this page. No email received?{" "}
          <span
            className="is-underlined is-clickable"
            onClick={handleResendVerification}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === "Enter") handleResendVerification()
            }}
          >
            Resend verification
          </span>
        </Notification>
      )}
      <Section
        title="Personal Details"
        addOns={{
          right: <EditDetailsButton route="/profile/edit" />,
        }}
      >
        <h4 className="has-text-black">
          {userData?.firstName} {userData?.lastName}
        </h4>
        <InfoIcon icon={faPhone}>
          Mobile Number: {userData?.mobileNumber}
        </InfoIcon>
        <InfoIcon icon={faEnvelope}>Personal Email: {userData?.personalEmail}</InfoIcon>
        <InfoIcon icon={faBirthdayCake}>Birthday: {patientBirthday}</InfoIcon>
        <InfoIcon icon={faIdCard}>Maxicare Card Number: {userData?.maxicareCardNumber}</InfoIcon>
      </Section>
      {addresses.length ? (
        <Section title="Addresses">
          {addresses.map((address, index) => {
            return (
              <AddressContainer
                address={address}
                index={index}
                setAddresses={setAddresses}
                handleDeleteCallback={handleDeleteCallback}
              />
            )
          })}
          {/* <Link
          to="/profile/add-address"
          state={{ nextRoute: "/profile", backRoute: "/profile" }}
        >
          + Add new address
        </Link> */}
        </Section>
      ) : null}

      <Section
        title="Emergency Contact Information"
        addOns={{
          right: <EditDetailsButton route="/profile/edit" />,
        }}
      >
        <h4 className="has-text-black">{userData?.emergencyContact?.name}</h4>
        <InfoIcon icon={faPhone}>
          Mobile Number: {userData?.emergencyContact?.mobileNumber}
        </InfoIcon>
        <InfoIcon icon={faEnvelope}>Email: {userData?.emergencyContact?.email}</InfoIcon>
        <InfoIcon icon={faUserFriends}>
          Relationship to employee:{" "}
          {userData?.emergencyContact?.relationship?.value ||
            userData?.emergencyContact?.relationship}
        </InfoIcon>
      </Section>
      <Section
        title="Account Details"
        addOns={{
          right: (
            <EditDetailsButton route="/profile/update-password">
              Update Password
            </EditDetailsButton>
          ),
        }}
      >
        <InfoIcon icon={faEnvelope}>{userData?.email}</InfoIcon>
        <InfoIcon icon={faLock}>
          &bull; &bull; &bull; &bull; &bull; &bull; &bull;
        </InfoIcon>
      </Section>
    </Fragment>
  )
}

export default ProfileInfo
